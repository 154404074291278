import React from "react";
import "./about.css";
import VelteLogo from "./../../assets/images/NEW_VELTE_black.png";
import CaseImg from "./../../assets/images/core_values_page.png";
import ScrollToTop from "./../../components/ScrollToTop";

const AboutUs = () => {
  return (
    <>
      <section id="about-us">
        <div className="container">
          <div className="text-box">
            <p>We are</p>
            <img src={VelteLogo} alt="Velte" />
            <h3>
              Welcome to velte technology solutions, where our dedication to
              assisting you with all your IT needs is top notch. We are
              dedicated to using the power of IT to provide solutions to your
              everyday need from tech solution to brand visual identities you
              could not have chosen a better place, come along let’s take you on
              a journey of fluid technological needs, where you will experience
              a seamless customer service to the most awesome products and
              services in the niche! We can’t wait to have you on board, come
              along, it’s time to explore!!!
            </h3>
          </div>
        </div>
      </section>
      <section className="coreValues">
        <div className="container">
          <div className="header">
            <h1>our core values</h1>
          </div>
          <div className="wrapper">
            <div className="speed">
              <article>
                <h2>speed</h2>
                Time is an asset and we believe to save time means to save more
                than money. That's why we work with speed. We get it done,
                faster and better than the rest.
              </article>
            </div>
            <div className="creativity">
              <article>
                <h2>creativity</h2>
                We present your brand with visual elements that force your
                clientelle to take a second look! Experience the unusual.
              </article>
            </div>
            <div className="excellence">
              <article>
                <h2>excellence</h2>
                We never fall short of the mark and make sure to exceed all your
                expectations. You'll find yourself toasting to our stellar
                achievements!
              </article>
            </div>
            <div className="affordability">
              <article>
                <h2>affordability</h2>
                Here our human face shines through! Profit is not our sole
                impetus, customer satisfaction is as well. We find a way to
                balance excellent product with smiling wallets. We've got you
                covered!
              </article>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default AboutUs;
