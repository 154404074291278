import React, { useState } from "react";
import "./webdevv.css";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { MdHomeRepairService } from "react-icons/md";
import icon1 from "../../assets/images/icons/icon1.png";
import icon2 from "../../assets/images/icons/icon2.png";
import icon3 from "../../assets/images/icons/icon3.png";
import { industries } from "../../data/industries";
import CreatableSelect from "react-select/creatable";
import { commonRoles } from "../../data/commonRoles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";

const services = [
  {
    title: "Graphic Design",
    icon: icon1,
  },
  {
    title: "Website Development",
    icon: icon2,
  },
  {
    title: "Mobile App Development",
    icon: icon3,
  },
];

const WebDevForms = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();

  const navigate = useNavigate();

  const handleServiceSelection = (service, index) => {
    setSelectedService(service.title);
    console.log(service.title);
  };

  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
    navigate("/qn");
  };
  const tabChanged = ({ prevIndex, nextIndex }) => {
    console.log("prevIndex", prevIndex);
    console.log("nextIndex", nextIndex);
  };
  return (
    <>
      <section id="wizard">
        <div className="wrapper">
          <div className="container">
            <FormWizard stepSize="sm" color="#000" onComplete={handleComplete}>
              <FormWizard.TabContent
                title="Service"
                icon={MdHomeRepairService()}
              >
                <h2>What Service Do You Need?</h2>
                <p>
                  Your response will help us to understand your needs better.
                </p>

                {/* a set of boxes (3 of them) with hover effects */}
                <div className="row mb-5">
                  {services.map((service, index) => (
                    <div className="col-md-4" key={index}>
                      <div
                        className="service-box"
                        onClick={() => handleServiceSelection(service, index)}
                      >
                        <span className="service-check-option">
                          <span>
                            <input
                              type="checkbox"
                              id={`service${index}`}
                              name="service1"
                              checked={selectedService === service.title}
                              readOnly
                              className="custom-checkbox"
                            />
                            <label
                              htmlFor={`service-checkbox-${index}`}
                            ></label>
                          </span>
                        </span>
                        <img
                          src={service.icon}
                          alt={service.title}
                          className="img-fluid"
                        />
                        <h3>{service.title}</h3>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="services"></div>
              </FormWizard.TabContent>
              <FormWizard.TabContent title="Additional Info" icon="ti-settings">
                <h2>Let's Get Some Info</h2>
                <p className="">
                  Please provide us with some additional information about your
                  project.
                </p>

                <div className="row form-info">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="fullname">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullname"
                        name="fullname"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company">Company</label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="phone">Phone Number</label>
                      <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: false,
                        }}
                        inputClass="form-control"
                        containerClass="phone-input-container"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">Your Role at </label>
                      <CreatableSelect
                        isClearable
                        placeholder="Select a Role"
                        options={commonRoles}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: "100%",
                            height: "100%",
                          }),
                        }}
                        theme={(theme) => {
                          return {
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#d4d4d4",
                              primary: "#000",
                            },
                          };
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="industry">Industry</label>
                      <CreatableSelect
                        isClearable
                        options={industries}
                        placeholder="Select an Industry"
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: "100%",
                            height: "100%",
                          }),
                        }}
                        theme={(theme) => {
                          return {
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#d4d4d4",
                              primary: "#000",
                            },
                          };
                        }}
                      />
                    </div>
                  </div>
                </div>
              </FormWizard.TabContent>
              <FormWizard.TabContent title="Last step" icon="ti-check">
                <h2>Processing Your Information</h2>
                <p>
                  Within the next few seconds, we will redirect you to the
                  project specific questionnaire.
                </p>

                <div className="row justify-content-center ">
                  <div className="col-12">
                    <div className="text-center mb-5">
                      <Spinner />
                    </div>
                    <a className="btn-link">Click here</a> to go to the
                    questionnaire, or wait to be redirected.
                  </div>
                </div>
              </FormWizard.TabContent>
            </FormWizard>
          </div>
        </div>
        {/* add style */}
        <style>{`
            @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
          `}</style>
      </section>
    </>
  );
};

export default WebDevForms;
