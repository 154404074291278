import React from "react";
import {
  CourseList,
  GetCourseInfo,
  InfiniteHorizontalScroll,
  ScrollToTop,
} from "./../../components";
import "./bootcamp.css";
import webDevImg from "./../../assets/images/web_dev.png";

const BootCamp = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <CourseList />
        </div>
      </section>
      <main>
        <section className="school">
          <div className="container">
            <div className="courses">
              <div className="course">
                <div className="left">
                  <div className="imgWrapper">
                    <img src={webDevImg} alt="Web development" />
                  </div>
                </div>
                <div className="right">
                  <div className="overview">
                    <h2>Overview of the Boot Camp</h2>
                    <ul>
                      <li>
                        Build a wide-ranging portfolio of projects and
                        applications in our UX/UI, Web development, Mobile
                        development, Graphic design and 3D Graphic Design with
                        Blender programs
                      </li>
                      <li>
                        All of our programs offer a part-time schedule, allowing
                        you to advance your skills without leaving work.
                      </li>
                      <li>
                        Our web development program also offers options to best
                        suit your schedule— with a full-time program for those
                        who wish to study at an accelerated pace.
                      </li>
                      <li>
                        Benefit from a wide range of career services to support
                        your transition into the industry.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="benefits">
                <div className="whatYouWillLearn">
                  <div className="content">
                    <h1>What You Will Learn</h1>
                    <article>
                      <p>
                        The Front-End Web Development Specialisation is
                        specifically designed to introduce the trainees to the
                        art and science of web development using:
                      </p>
                      <ul>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>Javascript</li>
                        <li>React JS</li>
                      </ul>
                    </article>
                  </div>
                </div>
                <div className="pathWays">
                  <div className="content">
                    <h1>Employment Pathways</h1>
                    <p>
                      After successful completion of the training programme,
                      Trainees have three employment pathways to choose from:
                    </p>
                    <ul>
                      <li>
                        You can get an employment offer at one of our Service
                        Centres.
                      </li>
                      <li>
                        You can use Veltetech as a platform to be self-employed
                        as we also encourage entrepreneurship.
                      </li>
                      <li>
                        You have an option to seek gainful employment on your
                        own.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>  */}
            </div>
            <InfiniteHorizontalScroll />
            <div className="aboutSchool">
              <div className="aboutBanner">
                <article>
                  <h2>Why Gain In-Demand Skills at our Boot Camp?</h2>
                  <p>
                    Our dedicated staff is passionate about creating a nurturing
                    and supportive environment where students feel valued,
                    respected, and empowered to explore their interests and
                    passions. We offer a comprehensive curriculum that is
                    designed to provide a well-rounded education.{" "}
                  </p>
                  <p>
                    Here, we embrace diversity and strive to create a culture of
                    inclusivity that celebrates the unique strengths and talents
                    of each student. We encourage students to think critically,
                    collaborate with others, and become active and engaged
                    members of their community.
                  </p>
                </article>
                <div className="btnHolder">
                  <a href="./enroll" className="btn3">
                    Enroll
                  </a>
                </div>
              </div>
            </div>
            <GetCourseInfo />
          </div>
        </section>
      </main>
      <ScrollToTop />
    </>
  );
};

export default BootCamp;
