import React from "react";
import { Link } from "react-router-dom";
import { navigationLinks } from "./../../constants/links";
// import { FaSistrix } from "react-icons/fa";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-menu">
            <div className="copyright">
              <div className="copy-name">
                &copy;
                <script>document.write(new Date().getFullYear());</script>
                <a
                  href="http://www.veltetech.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {""} Velte Technology Solutions
                </a>
              </div>
              <div className="top">
                <ul>
                  <li>
                    <a
                      href="/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="/add_choices" target="_blank">
                      Ad Choices
                    </a>
                  </li>
                  <li>
                    <a href="/trust" target="_blank" rel="noopener noreferrer">
                      Trust
                    </a>
                  </li>
                </ul>
              </div>
              <div className="bottom">
                <ul>
                  <li>
                    <a href="/donotshare" target="_blank">
                      Do not sell my personal information
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="menu-items">
              {navigationLinks.map((links) => {
                return (
                  <Link className="menu_item" key={links.id} to={links.url}>
                    {links.name}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
