import React from "react";
import { GetCourseInfo, ScrollToTop } from "../../components";

const BlenderCourse = () => {
  return (
    <>
      <section className="bootCampHero">
        <div className="container">
          <div className="left">
            <div className="heroTxtContent">
              <h1>3D Graphic Design with Blender</h1>
            </div>
            <div className="btnHolder">
              <a href="../enroll" className="btn4">
                Enroll
              </a>
            </div>
          </div>
          <div className="right"></div>
        </div>
      </section>
      <main>
        <div className="benefits container">
          <div className="whatYouWillLearn">
            <div className="content">
              {/* <h1>What You Will Learn</h1> */}
              <article>
                <p>
                  Explore the dynamic world of 3D Graphic Design through our
                  specialized course centered on cultivating the skills and
                  expertise essential for creating captivating visual
                  experiences. Tailored for individuals driven by a passion for
                  artistic expression, this program focuses on mastery in 3D
                  design, leveraging the powerful industry-standard software,
                  Blender.
                </p>
                <p>
                  Throughout the course, students will delve into the core
                  aspects of 3D modeling, texturing, lighting, and animation,
                  honing their abilities to craft intricate and immersive
                  digital environments. Using Blender's versatile features,
                  participants will bring their creative visions to life, from
                  character animations to dynamic scenes.
                </p>
                <p>
                  Our curriculum strikes a balance between artistic creativity
                  and technical proficiency, guiding students through Blender's
                  interface and functionalities. Through hands-on projects and
                  real-world simulations, participants will build a
                  comprehensive portfolio showcasing their proficiency in 3D
                  design principles.
                </p>
                <p>
                  Collaboration is emphasized through interdisciplinary
                  projects, mirroring the teamwork prevalent in the 3D design
                  industry. Whether aspiring to work in animation, game design,
                  virtual reality, or architectural visualization, this course
                  provides a solid foundation for diverse career paths.
                </p>
                <p>
                  Upon completion, graduates will emerge as skilled 3D graphic
                  designers, equipped to contribute their unique perspectives to
                  industries on the forefront of visual innovation. Enroll now
                  and transform your passion for creativity into a fulfilling
                  journey in 3D Graphic Design with Blender.
                </p>
              </article>
            </div>
          </div>
          {/* <div className="pathWays">
            <div className="content">
              <h1>Employment Pathways</h1>
              <p>
                After successful completion of the training programme, Trainees
                have three employment pathways to choose from:
              </p>
              <ul>
                <li>
                  You can get an employment offer at one of our Service Centres.
                </li>
                <li>
                  You can use Veltetech as a platform to be self-employed as we
                  also encourage entrepreneurship.
                </li>
                <li>
                  You have an option to seek gainful employment on your own.
                </li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className="container">
          <GetCourseInfo />
        </div>
      </main>
      <ScrollToTop />
    </>
  );
};

export default BlenderCourse;
