import React from "react";

const Questionnaire = () => {
  return (
    <div>
      <h1 style={{ color: "#fff" }}>Questionnaire</h1>
    </div>
  );
};

export default Questionnaire;
