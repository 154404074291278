import { Outlet, createBrowserRouter } from "react-router-dom";
import Layout from "../Layouts/Layout";
import QLayout from "../Layouts/QLayout";
import { AboutUs, BlenderCourse, BootCamp, ContactPage, DataAnalytics, Enroll, FrontEndWebDevelopment, GraphicDesign, Home, MobileDevelopment, NotFound, Portfolio, Questionnaire, ServicePage, UiUx, WebDevForms, WebDevelopment } from "../pages";

const QWrapper = () => {
    return (
        <QLayout>
            <Outlet />
        </QLayout>
    );
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                exact: true,
                element: <Home />
            },
            {
                path: '/services',
                element: <ServicePage />
            },
            {
                path: '/portfolio',
                element: <Portfolio />
            },
            {
                path: '/about',
                element: <AboutUs />
            },
            {
                path: '/contact',
                element: <ContactPage />
            },
            {
                path: '/bootcamp',
                element: <BootCamp />
            },
            {
                path: '/webdevforms',
                element: <WebDevForms />
            },
            {
                path: '/enroll',
                element: <Enroll />
            },
            {
                path: '*',
                element: <NotFound />
            },
            {
                path: '/bootcamp/3d-graphic-design-with-blender',
                element: <BlenderCourse />
            },
            {
                path: '/bootcamp/graphic-design',
                element: <GraphicDesign />
            },
            {
                path: '/bootcamp/mobile-development',
                element: <MobileDevelopment />
            },
            {
                path: '/bootcamp/web-development',
                element: <WebDevelopment />
            },
            {
                path: '/bootcamp/frontend-web-development',
                element: <FrontEndWebDevelopment />
            },
            {
                path: '/bootcamp/ui-ux',
                element: <UiUx />
            },
            {
                path: '/bootcamp/data-analytics',
                element: <DataAnalytics />
            }
        ]
    },
    {
        path: "/qn",
        element: <QWrapper />,
        children: [
            {
                path: "",
                element: <Questionnaire />
            }
        ]
    }
]);

export default router;