import React, { useState, useEffect } from "react";
import "./courses.css";

const CourseList = () => {
  const titles = [
    {
      id: 1,
      title: "3D Graphic Design with Blender",
      details:
        "  Explore the dynamic world of 3D Graphic Design through our specialized course centered on cultivating the skills and expertise essential for creating captivating visual experiences. Tailored for individuals driven by a passion for artistic expression, this program focuses on mastery in 3D design, leveraging the powerful industry-standard software, Blender.",
      url: "/bootcamp/3d-graphic-design-with-blender",
    },
    {
      id: 2,
      title: "Graphic Design",
      details:
        "Embark on a creative journey in the world of visual communication with our Graphic Design course. Designed for aspiring artists and communicators, this program provides students with a comprehensive set of skills to thrive in the dynamic field of graphic design. Throughout the course, students will master industry-standard design software, honing their abilities to create compelling visual narratives, eye-catching branding, and impactful multimedia content.",
      url: "/bootcamp/graphic-design",
    },
    {
      id: 3,
      title: "Fullstack Web Development",
      details:
        "As technology advances, our curriculum evolves to cover the latest frameworks and tools in the industry, empowering students with the ability to build robust and interactive web applications. From front-end development, focusing on the visual aspects of a website, to back-end development, managing server-side functionality, students will acquire a holistic understanding of the web development process.",
      url: "/bootcamp/web-development",
    },
    {
      id: 4,
      title: "Mobile Development",
      details:
        "Dive into the cutting-edge realm of mobile development with our specialized course, tailor-made for individuals passionate about creating innovative and impactful mobile applications. This program provides students with a comprehensive skill set to thrive in the rapidly evolving landscape of mobile technology.",
      url: "/bootcamp/mobile-development",
    },
    {
      id: 5,
      title: "UI/UX",
      details:
        "Embark on a transformative journey into the world of User Interface (UI) and User Experience (UX) design with our specialized course tailored for individuals seeking to craft seamless and visually stunning digital experiences. Our UI/UX Design program provides students with a holistic set of skills, blending creativity and technical expertise to meet the demands of the ever-evolving digital landscape.",
      url: "/bootcamp/ui-ux",
    },
    {
      id: 6,
      title: "Data Analytics",
      details:
        "Immerse yourself in the world of data with our specialized course in Data Analytics, designed for individuals eager to harness the power of information for insightful decision-making. This program equips students with a comprehensive skill set, blending statistical analysis and data interpretation to navigate the complexities of the modern data landscape.",
      url: "/bootcamp/data-analytics",
    },
    {
      id: 7,
      title: "Frontend Web Development",
      details:
        "Dive into the dynamic world of Frontend Web Development with our specialized course, crafted for individuals eager to shape the visual and interactive aspects of the digital landscape. This program provides students with a comprehensive skill set, encompassing the latest technologies and industry best practices to thrive in the fast-paced field of web development.",
      url: "/bootcamp/frontend-web-development",
    },
  ];

  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let timeout;

    if (!isHovered) {
      timeout = setTimeout(() => {
        const nextIndex = (currentTitleIndex + 1) % titles.length;
        setCurrentTitleIndex(nextIndex);
      }, 6000); // Set the time (in milliseconds) for how long each title should be displayed
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [currentTitleIndex, titles, isHovered]);

  const handleTitleHover = (index) => {
    setCurrentTitleIndex(index);
    setIsHovered(true);
  };

  const handleTitleLeave = () => {
    setIsHovered(false);
  };

  const currentTitle = titles[currentTitleIndex];

  return (
    <>
      <div className=" courseWrapper">
        <div className="details">
          {currentTitle && (
            <div className="detailContent">
              {/* <h3>{currentTitle.title}</h3> */}
              <p>{currentTitle.details}</p>
              <div className="btnHolder">
                <a href={currentTitle.url} className="btn4">
                  learn more
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="titles">
          <ul>
            {titles.map((title, index) => (
              <li
                key={title.id}
                className={`title-item ${
                  index === currentTitleIndex ? "bold" : ""
                }`}
                onClick={() => setCurrentTitleIndex(index)}
                onMouseEnter={() => handleTitleHover(index)}
                onMouseLeave={handleTitleLeave}
              >
                {title.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default CourseList;
