import React from "react";
import "./service.css";
import fluidLaptop from "./../../assets/videos/fluidLaptop.mp4";
import shipTravel from "./../../assets/videos/shipTravel.mp4";
import media from "./../../assets/videos/MediaAnimation.mp4";
import creativeWriters from "./../../assets/videos/CreativeWriters.mp4";
import ScrollToTop from "./../../components/ScrollToTop";

const ServicePage = () => {
  return (
    <>
      <section id="web-app-solutions">
        <div className="web-app-solutions-left-col">
          <video autoPlay playsInline muted loop id="left-col-vid">
            <source src={fluidLaptop} type="video/mp4" />
          </video>
        </div>

        <div className="web-app-solutions-right-col">
          <div className="right-col-text-box">
            <h1>
              Web and <br /> App solutions
            </h1>
            <p>
              We provide aesthetically pleasing and intuitive web and mobile app
              solutions that function seamlessly and put you ahead of the
              competition in your industry.
            </p>
            <a className="btn4" href="/">
              Learn more
            </a>
          </div>
        </div>
      </section>

      <section id="art-design-hub">
        <div className="art-design-hub-row">
          <div className="art-design-hub-left-col">
            <video autoPlay playsInline muted loop id="left-col-vid">
              <source src={shipTravel} type="video/mp4" />
            </video>
          </div>
          <div className="art-design-hub-right-col">
            <div className="art-right-col-text-box">
              <h1>
                Art & <br /> Design Hub
              </h1>
              <p>
                Our work encompasses graphic design and animations. We create
                and design with your brand in mind.
              </p>
              <a className="btn3" href="/">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="media">
        <div className="media-left-col">
          <video autoPlay playsInline muted loop id="left-col-vid">
            <source src={media} type="video/mp4" />
          </video>
        </div>

        <div className="media-right-col">
          <div className="media-right-col-text-box">
            <h1>Media</h1>
            <p>
              Our media owns and uses digital platforms, which strengthens our
              relationship with audiences in every way. We uphold strict ethical
              principles. Through our production efforts, we are dedicated to
              improving the quality of life in the areas in which we operate.
            </p>
            <a className="btn4" href="/">
              Learn more
            </a>
          </div>
        </div>
      </section>

      <section id="creative-writing">
        <div className="creative-writing-row">
          <div className="creative-writing-left-col">
            <video autoPlay playsInline muted loop id="left-col-vid">
              <source src={creativeWriters} type="video/mp4" />
            </video>
          </div>
          <div className="creative-writing-right-col">
            <div className="creative-writing-right-col-text-box">
              <h1>
                Creative <br /> writing
              </h1>
              <p>
                Relevant web copy is the currency of the Internet. We write
                in-depth copies that makes your site more readable and engaging.
              </p>
              <a className="btn3" href="/">
                Learn more
              </a>
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default ServicePage;
