import React from "react";
import "./spinner.css";

const Spinner = () => {
  return (
    <div class="lds-spinner" bis_skin_checked="1">
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
      <div bis_skin_checked="1"></div>
    </div>
  );
};

export default Spinner;
