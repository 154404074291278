import React, { useState } from "react";
import "./get-course-info.css";
import { API_URL } from "../../api/config";

const GetCourseInfo = () => {
  const [step, setStep] = useState(1);
  const [course, setCourse] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitButtonValue, setSubmitButtonValue] = useState("Submit");

  const totalSteps = 3;

  const validateStep = () => {
    let newErrors = {};
    if (step === 1 && !course) {
      newErrors.course = "Please select a course";
    }
    if (step === 2 && !/^\S+@\S+\.\S+$/.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (step === 3 && !name) {
      newErrors.name = "Please enter your name";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      console.log("Course value:", course);
      if (step < totalSteps) {
        setStep(step + 1);
      } else {
        // All steps completed, set success state to true
        setSuccess(true);
      }
    }
  };

  const handleSubmit = async () => {
    if (validateStep()) {
      console.log("Course:", course);
      console.log("Email:", email);
      console.log("Name:", name);

      setSubmitButtonValue("Getting info...");
      try {
        // Send form data to backend
        const response = await fetch(`${API_URL}/request-course-info`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ course, email, name }),
        });

        if (response.ok) {
          console.log("Form submitted successfully");
          setSuccess(true);
        } else {
          console.error("Form submission failed");
          setSubmitButtonValue("Submit");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitButtonValue("Submit");
      }
    }
  };

  return (
    <>
      <div className="getInfoBoxWrapper">
        <div className="getInfoBox">
          {success ? (
            <div className="successMessage">
              <p>
                Thank you for your interest. <b>Check your email</b> . Course
                info has been sent to you.
              </p>
            </div>
          ) : (
            <>
              <h2>Get Course Info</h2>
              {step === 1 && (
                <div className="inputBox">
                  <select
                    value={course}
                    onChange={(e) => setCourse(e.target.value)}
                    required
                  >
                    <option value="">Select a course</option>
                    <option value="3D Graphic Design with Blender">
                      3D Graphic Design with Blender
                    </option>
                    <option value="Graphic Design">Graphic Design</option>
                    <option value="Fullstack Web Development">
                      Fullstack Web Development
                    </option>
                    <option value="Mobile Development">
                      Mobile Development
                    </option>
                    <option value="UI_UX">UI/UX</option>
                    <option value="Data Analytics">Data Analytics</option>
                    <option value="Frontend Web Development">
                      Frontend Web Development
                    </option>
                  </select>
                  {errors.course && <p className="error">{errors.course}</p>}
                  <button onClick={handleNext} className="btn3">
                    Next
                  </button>
                </div>
              )}

              {step === 2 && (
                <div className="inputBox">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                  <button onClick={handleNext} className="btn3">
                    Next
                  </button>
                </div>
              )}

              {step === 3 && (
                <div className="inputBox">
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                  <input
                    type="submit"
                    onClick={handleSubmit}
                    value={submitButtonValue}
                    className="btn3"
                  />
                </div>
              )}

              <div className="progressBar">
                <progress value={step} max={totalSteps}></progress>
                <span>
                  {step}/{totalSteps}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GetCourseInfo;
