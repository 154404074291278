import React from "react";
import headerVid from "./../../assets/videos/headerVid.mp4";
import Bubble1 from "./../../assets/images/bubbles1.gif";
import Bubble2 from "./../../assets/images/bubbles2.gif";
import spaceShuttle from "./../../assets/images/media.gif";
import Diamond from "./../../assets/videos/Diamond.mp4";
import ScrollToTop from "./../../components/ScrollToTop";
import "./home.css";

const Home = () => {
  return (
    <>
      <section id="home-welcome">
        <header className="showcase">
          <div className="home_vid_text">
            <div className="container">
              <h1>Welcome</h1>

              <a href="./about" className="btn4">
                About Us
              </a>
            </div>
          </div>

          <video autoPlay muted playsInline loop id="hero-bgVid">
            <source src={headerVid} type="video/mp4" />
          </video>
        </header>
      </section>

      <section id="fluid">
        <div className="fluid-row">
          <div className="fluid-left-col">
            <img src={Bubble1} alt="Bubble" />
          </div>

          <div className="fluid-text-col">
            <h1>
              We <br /> are <br /> Fluid
            </h1>
            <p>
              From Tech solutions to brand visual identities, we handle it all!
            </p>
            <a href="./services" className="btn3">
              What we do
            </a>
          </div>

          <div className="fluid-right-col">
            <img src={Bubble2} alt="Bubble" />
          </div>
        </div>
      </section>

      <section id="value">
        <div className="value-container">
          <div className="value-text-box">
            <h1>
              We <br /> value <br /> you
            </h1>
            <p>
              We're always working in your best interest. <br /> Find out how
              Our core values value you!
            </p>
            <a className="btn4" href="./about">
              Our Core Values
            </a>
          </div>

          <video
            autoPlay
            playsInline
            muted
            loop
            id="value-bgVid"
            className="value-bgvid"
          >
            <source src={Diamond} type="video/mp4" />
          </video>
        </div>
      </section>

      <section id="dream">
        <div className="container">
          <div className="dreamContent">
            <div className="top">
              <div className="backGroundText">
                <h2>You dream,</h2>
              </div>
              <div className="foreGround">
                <img
                  className="centerImage"
                  src={spaceShuttle}
                  alt="space shuttle"
                />
                <div className="foreText">
                  <h1>We achieve</h1>
                </div>
              </div>
            </div>
            <div className="bottom">
              <p>
                At Vetle Technology Solutions, we bring all your thoughts and
                imaginations to reality letting the whole world know how great
                you are at what you do.
              </p>
              <a href="./portfolio" className="btn3">
                our portfolio
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="ideas">
        <div className="container">
          <div className="textContent">
            <h1>
              Great ideas <br /> <small>do exist!</small>
            </h1>
            <a href="./contact" className="btn4">
              contact us
            </a>
          </div>
        </div>
      </section>

      <ScrollToTop />
    </>
  );
};

export default Home;
