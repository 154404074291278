import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  BsTwitter,
  BsWhatsapp,
  BsFacebook,
  BsInstagram,
  BsYoutube,
} from "react-icons/bs";
import { SiTiktok } from "react-icons/si";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { ScrollToTop } from "./../../components";
// import emailjs from "@emailjs/browser";
import "./contact.css";
import { Notification } from "../../components";
import { API_URL } from "../../api/config";

const ContactPage = () => {
  const [submitButtonValue, setSubmitButtonValue] = useState("Send");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("info");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [budget, setBudget] = useState("");
  const [projectDetails, setProjectDetails] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form fields
    if (!name.trim()) {
      handleNotification("Name is required", "error");
      return;
    }

    if (!email.trim() || !/^\S+@\S+\.\S+$/.test(email)) {
      handleNotification("Invalid email address", "error");
      return;
    }

    if (!company.trim()) {
      handleNotification("Company is required", "error");
      return;
    }

    if (!budget.trim()) {
      handleNotification("Budget is required", "error");
      return;
    }

    handleFormSubmission();
  };

  const handleNotification = (message, type) => {
    setShowNotification(true);
    setNotificationMessage(message);
    setNotificationType(type);

    // hide the notification after  5 seconds
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  const handleFormSubmission = () => {
    setSubmitButtonValue("Sending");

    // Create a FormData object
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("company", company);
    formData.append("budget", budget);
    formData.append("projectDetails", projectDetails);

    fetch(`${API_URL}/contact-form-submit`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);

        // Log form data values
        // for (const [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }

        if (data.success) {
          setName("");
          setEmail("");
          setCompany("");
          setBudget("");
          setProjectDetails("");

          handleNotification(data.message, "success");
        } else {
          console.error("submission failed:", data.error);
          handleNotification(
            data.message || "Submission failed. Please try again.",
            "error"
          );
        }

        setSubmitButtonValue("Send");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleNotification(
          "An error occurred. Please try again later.",
          "error"
        );
        setSubmitButtonValue("Send");
      });
  };

  return (
    <>
      <section className="hero">
        <div className="container">
          <div className="content">
            <div className="top">
              <h1>contact us</h1>
              <p>
                Looking for a design partner? <br />
                Reach out to us
              </p>
            </div>
            <div className="bottom">
              <div className="social">
                <Link className="icons">
                  <BsFacebook className="icon" />
                </Link>
                <Link className="icons">
                  <BsInstagram className="icon" />
                </Link>
                <Link className="icons">
                  <BsWhatsapp className="icon" />
                </Link>
                <Link className="icons">
                  <BsTwitter className="icon" />
                </Link>
                <Link className="icons">
                  <SiTiktok className="icon" />
                </Link>
                <Link className="icons">
                  <BsYoutube className="icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section className="contact">
          <div className="container">
            <div className="left">
              <div className="txtContent">
                <div className="header">
                  <h1>
                    What will be <br /> the next step?
                  </h1>
                  <p>You are one step away to build your perfect product</p>
                </div>
                <div className="steps">
                  <div class="wrapper">
                    <ul class="StepProgress">
                      <li class="StepProgress-item is-done">
                        <strong>We'll prepare a proposal</strong>
                        Required scope,timeline and price will be included if
                        you provide us with detail information about a project.
                      </li>
                      <li class="StepProgress-item is-done">
                        <strong>Together we discuss it</strong>
                        let's get acquainted and discuss all the possible
                        variants and options. Zoom meetings always works great.
                      </li>
                      {/* change className is-done to current if you want numbers */}
                      <li class="StepProgress-item is-done">
                        <strong>Let's start building</strong>
                        When the contract is signed, and all goals are set we
                        can start the first sprint.
                      </li>
                      <li class="StepProgress-item">
                        <strong>Get in touch, every step!</strong>
                      </li>
                      {/* <li class="StepProgress-item">
                      <strong>Provide feedback</strong>
                    </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <div className="formsContainer">
                <div className="header">
                  <div className="icon">
                    <FaEnvelopeOpenText />
                  </div>
                  <div className="text">
                    <p>
                      Write us a few words about your project and we will
                      prepare a proposal for you within 24 hours.
                    </p>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="formGroup">
                    <input
                      type="text"
                      name="fullname"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      id="name"
                      placeholder="Your Name"
                    />

                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      placeholder="E-mail"
                    />
                  </div>
                  <div className="formGroup">
                    <input
                      type="text"
                      name="company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      id="company"
                      placeholder="Company"
                    />

                    <input
                      type="text"
                      name="budget"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                      id="budget"
                      placeholder="Budget"
                    />
                  </div>
                  <div className="details">
                    <textarea
                      name="projectDetails"
                      id="projectDetails"
                      cols="30"
                      rows="10"
                      value={projectDetails}
                      onChange={(e) => setProjectDetails(e.target.value)}
                      placeholder="Project Details"
                    ></textarea>
                  </div>

                  <input
                    type="submit"
                    value={submitButtonValue}
                    className="btn3"
                  />

                  <div className="formFooter">
                    <p>
                      If you can't put into detail your product idea or
                      structure, we have a
                      <Link to={"/webdevforms"}>questionnaire</Link> that you
                      can fill to make things easy.
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="address">
          <div className="container">
            <div className="phone">
              <div>
                <strong>Call Us</strong>
                <p>16479016884</p>
              </div>
              {/* <div>
                <strong>Visit Us</strong>
                <article>
                  21 Knupp Road <br /> Barrie, Ontario
                </article>
              </div> */}
            </div>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.957406909091!2d-79.73587622403588!3d44.372464871077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882abd5c39ea3111%3A0x330340f95540e198!2s21%20Knupp%20Rd%2C%20Barrie%2C%20ON%20L4N%200P8%2C%20Canada!5e0!3m2!1sen!2sgh!4v1681808860694!5m2!1sen!2sgh"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Velte Technology Solutions"
              ></iframe>
            </div>
          </div>
        </section>
      </main>
      <ScrollToTop />

      {showNotification && (
        <Notification
          message={notificationMessage}
          notificationType={notificationType}
          onClose={() => setShowNotification(false)}
        />
      )}
    </>
  );
};

export default ContactPage;
