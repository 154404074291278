import React, { useEffect } from "react";
import "./scroll.css";

function Scroller({ children, speed = "default", direction = "left" }) {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className={`scroller`} data-speed={speed} data-direction={direction}>
      <div className="scroller__inner">{children}</div>
    </div>
  );
}

function InfiniteHorizontalScroll() {
  return (
    <div>
      {/* <h1 style={{ textAlign: "center" }}>Infinite Scroll Animation</h1> */}

      <Scroller speed="slow">
        <ul className="tag-list scroller__inner">
          <li>HTML</li>
          <li>CSS</li>
          <li>JS</li>
          <li>React</li>
          <li>MobDev</li>
          <li>WebDev</li>
          <li>Graphic Design</li>
          <li>UI/UX</li>
        </ul>
      </Scroller>

      {/* <Scroller direction="right" speed="slow">
        <>
          <img src="https://i.pravatar.cc/150?img=1" alt="" />
          <img src="https://i.pravatar.cc/150?img=2" alt="" />
          <img src="https://i.pravatar.cc/150?img=3" alt="" />
          <img src="https://i.pravatar.cc/150?img=4" alt="" />
          <img src="https://i.pravatar.cc/150?img=5" alt="" />
          <img src="https://i.pravatar.cc/150?img=6" alt="" />
        </>
      </Scroller> */}
    </div>
  );
}

export default InfiniteHorizontalScroll;
